            import * as THREE from 'three'  
			import './style.css'

			//import Stats from 'three/examples/jsm/libs/stats.module.js';

			import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';
			import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
			import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

			// import * as dat from 'dat.gui'
			
			//typewriter
			import Typewriter from 'typewriter-effect/dist/core';
			var app = document.getElementById('interface2');
 
			var typewriter = new Typewriter(app, {
				loop: true,
				delay: 75,
				autoStart: true
			});

			const typeArea = document.getElementById('blocker')

			typeArea.addEventListener("click", function(){
				typewriter
				.pauseFor(2500)
				.typeString('Hi there, ')
				.pauseFor(300)
				.deleteChars(10)
				.typeString('Looks like the landing went okay... ')
				.pauseFor(500)
				.deleteChars(36)
				.typeString('For this section of your <span style="color: #00ff00;">Soft//Quest,</span> we need you to explore this old weather station ')
				.pauseFor(800)
				.deleteChars(86)
				.typeString('It`s been out of use for a while, but if it`s still operational the geo-climatic Mages back at the comm can use the data it collects to make more accurate predictions...')
				.pauseFor(800)
				.typeString(' Explore the area using WASD or your arrow keys, and move your mouse to look around.')
				.pauseFor(1000)
				.typeString(' Good luck ...')
				.pauseFor(1000)

				.start();
			});

			
			
				//mouse coordinates
			// coordinate function that calculate the X 
			// coordinate and Y coordinates 
			// function showCoords(event) {
			// 	var x = event.clientX;
			// 	var y = event.clientY;
			// 	//console.log("X coords: " + x + ", Y coords: " + y)
			// 	var coor = "X coords: " + x + ", Y coords: " + y;
			// 	document.getElementById("demo").innerHTML = coor;
			//   }
			  
			// function clearCoor() {
			// 	document.getElementById("demo").innerHTML = "";
			//   }
			/* 
			debug
			*/ 
			// const gui = new dat.GUI({closed : true, width : 400})

			let container, stats;
			let camera, controls, scene, renderer;
			let mesh, texture;
			let model = null
			let mixer = null

			const worldWidth = 256, worldDepth = 256;
			const clock = new THREE.Clock();
			
			let previousTime = 0

			init();
			animate();
		


			function init() {

				

				const updateAllMaterials = () =>
				{
					scene.traverse((child) =>
					{
						//console.log(child)
						if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
						if(child instanceof THREE.Mesh)
						{
							child.material.needsUpdate = true
							child.castShadow = true
							child.receiveShadow = true
						}
					})
				}

				container = document.getElementById( 'container' );

				//camera
				let FOV
				let FAR
				let NEAR = 0.1

				// Mobile camera
				if (window.innerWidth <= 768) {
				FOV = 50
				FAR = 1200
				// 769px - 1080px screen width camera
				} else if (window.innerWidth >= 769 && window.innerWidth <= 1080) {
				FOV = 50
				FAR = 1875
				// > 1080px screen width res camera
				} else {
				FOV = 40
				FAR = 2000
				}

				// this.camera = new THREE.PerspectiveCamera(
				// FOV,
				// window.innerWidth / window.innerHeight,
				// NEAR,
				// FAR
				// )

				camera = new THREE.PerspectiveCamera( FOV, window.innerWidth / window.innerHeight, NEAR, FAR );

				scene = new THREE.Scene();
				scene.background = new THREE.Color( 0xffc0c0 );
				scene.fog = new THREE.FogExp2( 0xffc0c0, 0.02);

				//const data = generateHeight( worldWidth, worldDepth );

				// objects
    		// /**
    		// * Models
 
				const dracoLoader = new DRACOLoader()
				dracoLoader.setDecoderPath('/draco/')
				// dracoLoader.load(
				// 	'/models/bunny_10.drc',
				// 	(geometry) =>
				// 	{
				// 		const material = new THREE.MeshStandardMaterial( { color: 0x606060 } );
				// 		const mesh = new THREE.Mesh( geometry, material );
				// 		mesh.scale.set(1000,1000,1000)
						
				// 		scene.add( mesh );
				// 		// console.log(gltf)
				// 		// model = gltf.scene
						
				// 		// model.scale.set(5,5,5)
				// 		// // model.position.z = -10
				// 		// // model.position.y = 10
				// 		// // model.rotation.y = 1
						
				// 		// model.castShadow = true
				// 		// model.receiveShadow = true
						
						
				// 		// model.magFilter = THREE.NearestFilter
				// 		// scene.add(model)
						
				// 		// mixer = new THREE.AnimationMixer(gltf.scene)
            	// 		// const action = mixer.clipAction(gltf.animations[0])
				// 		// const action0 = mixer.clipAction(gltf.animations[0])
				// 		// const action1 = mixer.clipAction(gltf.animations[1])
				// 		// const action2 = mixer.clipAction(gltf.animations[2])
				// 		// const action3 = mixer.clipAction(gltf.animations[3])
				// 		// const action4 = mixer.clipAction(gltf.animations[4])
				// 		// const action5 = mixer.clipAction(gltf.animations[5])
						
           		// 		// action0.play()
				// 		// action1.play()
				// 		// action2.play()
				// 		// action3.play()
				// 		// action4.play()
				// 		// action5.play()
          		// 		//console.log(mixer)
				// 		//updateAllMaterials()
				// 	},
				// 	// called as loading progresses
				// 	function ( xhr ) {
				
				// 		console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				
				// 	},
				// 	// called when loading has errors
				// 	function ( error ) {
				
				// 		console.log( 'An error happened' );
				
				// 	}
				// );
				const gltfLoader = new GLTFLoader()
				gltfLoader.setDRACOLoader(dracoLoader)

				gltfLoader.load(
					'/draco/island38.glb',
					(gltf) =>
					{
						//console.log(gltf)
						model = gltf.scene
						
						model.scale.set(1,1,1)
						// model.position.z = -10
						// model.position.y = 10
						// model.rotation.y = 1
						
						model.castShadow = true
						model.receiveShadow = true
						
						
						model.magFilter = THREE.NearestFilter
						scene.add(model)
						
						mixer = new THREE.AnimationMixer(gltf.scene)
            			const action = mixer.clipAction(gltf.animations[0])
						const action0 = mixer.clipAction(gltf.animations[0])
						const action1 = mixer.clipAction(gltf.animations[1])
						const action2 = mixer.clipAction(gltf.animations[2])
						const action3 = mixer.clipAction(gltf.animations[3])
						const action4 = mixer.clipAction(gltf.animations[4])
						
						
           				action0.play()
						action1.play()
						action2.play()
						action3.play()
						action4.play()
						
          				//console.log(mixer)
						updateAllMaterials()
					}
				)

				camera.position.set( -60, 2, 60 );
				camera.lookAt(0,0,0)
				// gui.add(camera.position, 'z').min(- 500).max(500).step(1).name('Cameraz')
				// gui.add(camera.position, 'x').min(- 500).max(500).step(1).name('Camerax')
				// gui.add(camera.position, 'y').min(- 500).max(500).step(1).name('Cameray')

    
				const directionalLight = new THREE.DirectionalLight('#f2ff73', 1)
				directionalLight.position.set(-20, 80, -80)
				
				directionalLight.castShadow = true
				directionalLight.shadow.mapSize.width = 2048
				//directionalLight.shadow.camera.position.y = 800
				directionalLight.shadow.mapSize.height = 2048
				directionalLight.shadow.camera.near = 1
				directionalLight.shadow.camera.far = 500
				directionalLight.shadow.bias = - 0.0001
				
				// const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
				// scene.add(directionalLightCameraHelper)
				directionalLight.shadow.camera.scale.set(30,30,30)

				const ambientLight = new THREE.AmbientLight('#9de0ff', 0.15)
				scene.add(ambientLight)
				
				const parameters = {
					color: 0xff0000
				}

				// gui
				// .addColor(parameters, 'color').name('directionalLight color')
				// .onChange(() =>
				// {
				// 	directionalLight.color.set(parameters.color)
					
				// })
				// gui
				// .addColor(parameters, 'color').name('AmbientLight color')
				// .onChange(() =>
				// {
				// 	ambientLight.color.set(parameters.color)
					
				// })
				// gui
				// .addColor(parameters, 'color').name('Fog color')
				// .onChange(() =>
				// {
				// 	scene.fog.color.set(parameters.color)
					
				// })
				// gui
				// .addColor(parameters, 'color').name('Background color')
				// .onChange(() =>
				// {
				// 	scene.background.set(parameters.color)
					
				// })
				// gui.add(ambientLight, 'intensity').min(0).max(10).step(0.001).name('AmbientlightIntensity')
				// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('DirecionallightIntensity')
				// gui.add(directionalLight.position, 'x').min(- 5).max(500).step(0.1).name('lightX')
				// gui.add(directionalLight.position, 'y').min(- 5).max(500).step(0.1).name('lightY')
				// gui.add(directionalLight.position, 'z').min(- 5).max(500).step(0.1).name('lightZ')
								
				// const helper = new THREE.DirectionalLightHelper( directionalLight, 50 ) 
				// scene.add( helper )
				scene.add(directionalLight)

	
					
				renderer = new THREE.WebGLRenderer({
					powerPreference: "high-performance"
					// antialias:true
				});
				//renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
				
				renderer.setSize( window.innerWidth, window.innerHeight );
				container.appendChild( renderer.domElement );
				renderer.shadowMap.enabled = true
				renderer.shadowMap.type = THREE.PCFSoftShadowMap
				renderer.outputEncoding = THREE.sRGBEncoding
				//renderer.toneMapping = THREE.ACESFilmicToneMapping
				//renderer.a
				
				
	

				controls = new FirstPersonControls( camera, renderer.domElement );
				controls.movementSpeed = 20;
				controls.lookSpeed = 0.1;

				//stats = new Stats();
				//container.appendChild( stats.dom );

				window.addEventListener( 'resize', onWindowResize );
				

			}

			function loadDracoMesh(dracoFile) {
				dracoLoader.load(dracoFile, function ( geometry ) {
				  geometry.computeVertexNormals();
		  
				  var material = new THREE.MeshStandardMaterial( { vertexColors: THREE.VertexColors } );
				  var mesh = new THREE.Mesh( geometry, material );
				  mesh.castShadow = true;
				  mesh.receiveShadow = true;
				  scene.add( mesh );
				} );
			  }

			  
			function onWindowResize() {

				camera.aspect = window.innerWidth / window.innerHeight;
				camera.updateProjectionMatrix();

				renderer.setSize( window.innerWidth, window.innerHeight );

				controls.handleResize();

			}


			function animate() {
				requestAnimationFrame( animate );
				//  const elapsedTime = clock.getElapsedTime()
				//  const deltaTime = elapsedTime - previousTime
				// previousTime = elapsedTime
				//const mixerUpdateDelta = clock.getDelta();
				//console.log(mixerUpdateDelta)
				const time = performance.now();
				//console.log(time/ 1000000)
				

				if( mixer !== null)
				{
					mixer.update(time/ 10000000);
					// mixer.update(clock.getDelta();
					//console.log(mixer)
					
				}
				
				controls.update( clock.getDelta() );
				render();
				//stats.update();
				
			}

			
			function render() {

				//controls.update( clock.getDelta() );
				renderer.render( scene, camera );

			}

			